import React, {useState} from "react"
import {graphql} from "gatsby";
import Layout from "../components/layout";
import "./toddler-track.css";
import Img from "gatsby-image";
import Button from "react-bootstrap/Button";
import {Row, Col, Form, Alert, Container,} from "react-bootstrap";
import styles from "./paid-family-leave.module.css";
import DonateBtn from "../donate-btn";
import formStyle from "./form.module.css";
import addToMailchimp from "gatsby-plugin-mailchimp";
import globalStyle from "../styles/global.module.css";

const PaidFamilyLeavePage = ({data}) => {

  const [subscriptionFormState, setSubscriptionFormValues] = useState({email: ""});
  const [showSubscriptionAlert, setShowSubscriptionAlert] = useState({show: false, msg: ""});
  const [showSubscriptionError, setShowSubscriptionErrorAlert] = useState({show: false, onField: ""});
  const [subscriptionFormResult, setSubscriptionFormResult] = useState({});
  const submitSubscriptionFormUrl = "https://momsonmaternity.us17.list-manage.com/subscribe/post?u=abb88870b50fd9c89b14ecf52&amp;id=deb0bc8045";


  const resetSubscriptionFormAfterSubmit = () => {
    setSubscriptionFormValues({
      email: ""
    });
  }

  const _handleSubscriptionFormSubmit = async (e) => {
    e.preventDefault();

    const requiredFields = ["email"];
    for (const field in subscriptionFormState) {
      if(requiredFields.indexOf(field) !== -1) {
        if(!subscriptionFormState[field]) {
          setShowSubscriptionErrorAlert({
            show: true,
            onField: field.charAt(0).toUpperCase()+ field.slice(1)
          });

          return;
        }
      }
    }

    const result = await addToMailchimp(subscriptionFormState.email, {}, submitSubscriptionFormUrl);
    setSubscriptionFormResult(result);

    setShowSubscriptionAlert({show: true});

    if (result.result === 'success') {
      resetSubscriptionFormAfterSubmit();
    } else {
      const alreadyAMemberError = subscriptionFormState.email + ` is already subscribed to list`
      if (result.result === 'error' && result.msg.includes(alreadyAMemberError)) {
        setShowSubscriptionAlert({
          show: true,
          msg: 'You already subscribed to list!'
        });
      } else {
        setShowSubscriptionAlert({
          show: true,
          msg: result.msg
        });
      }
    }
  };

  const handleSubscriptionFormChange = (e) => setSubscriptionFormValues({
    ...subscriptionFormState,
    [e.target.name]: e.target.value,
  });

  return (
    <Layout className="site-content">
      <Container className={styles.paidFamilyContainer}>
        <div>
          <h1 className={styles.title}>Did You Know?</h1>
        </div>
        <div>
          <Row>
            <Col xs={12} md={4} sm={4}>
              <div className={styles.imageContainer}>
                <Img fluid={data.allFile.edges[0].node.childImageSharp.fluid}></Img>
              </div>
              <p className={styles.factContent}>The United States of America is one of the only countries in the world that does not have NATIONAL Paid Family Leave by law.</p>
            </Col>
            <Col xs={12} md={4} sm={4}>
              <div className={styles.imageContainer}>
                <Img fluid={data.allFile.edges[1].node.childImageSharp.fluid}></Img>
              </div>
              <p className={styles.factContent}>Only five States have passed STATE Paid Family Leave laws in the USA as of 2020.</p>
            </Col>
            <Col xs={12} md={4} sm={4}>
              <div className={styles.imageContainer}>
                <Img fluid={data.allFile.edges[2].node.childImageSharp.fluid}></Img>
              </div>
              <p className={styles.factContent}>Only 16% of American private-industry employees had access to Paid Family Leave in March 2018.</p>
            </Col>
          </Row>

          <h1 className={styles.title}>Let's Make Change</h1>

          <div className={styles.secondSection}>
            <p>National Paid Family Leave is an opportunity for a stronger workforce, mentally healthier individuals, and better connected family support systems. Paid Family Leave is a win for workers, businesses, and the community as a whole. </p>
            <p>Parents should feel safe staying home with their baby, without the risk of financial hardship or loss of career potential. Help us get NATIONAL PAID FAMILY LEAVE passed so that every individual has an opportunity to heal their body, bond with baby, and adequately prepare themselves before returning to the workforce. </p>
            <p>Democrats and Republicans both say they want this. It is a bi-partisan concern and the media is not giving this enough attention. If we, the citizens, make it clear that we will stand together for fair provision of paid family leave, our government officials will hear our cries. Moms on Maternity is positioned to ensure that our concerns are heard. </p>
            <p>To date, Moms on Maternity has been featured on FOX News and CBS News, demonstrating the ability to get premiere coverage of this conversation. Help us take the steps to get a formal bill presented and passed. We will not stop until it is done and we know how to be bold.</p>
          </div>

          <Row className={styles.redirectButtonContainer + " justify-content-center"}>
            <Col sm={12} md={6}>
              <Button className={[globalStyle.btnPrimary, globalStyle.redirectButtonPink, styles.redirectTo, "md-4"].join(" ")} href="/petition/" role="button">
                Sign the Petition to Get
                <br/>
              Paid Family Leave Passed</Button>
            </Col>
          </Row>

          <h1 className={styles.title}>Let's Make Change</h1>

          <div className={styles.donateButton}>
            <a target="_blank"
               rel="noopener noreferrer"
               href="https://www.paypal.com/donate/?token=10CXhP8F0wvyqOuslkBePn-8-D_QjNQgfiYm800fUF8jvZ6BQ6twRVFjZytXANy--L3Mh0&country.x=US&locale.x=EN_US"><DonateBtn/></a>
          </div>

          <h1 className={styles.title}>Subscribe</h1>

          <div>
            <p>Moms on Maternity is tracking Paid Family Leave conversations. Subscribe to our emails for up-to-date information on American paid family leave laws and legislation.</p>
          </div>

          <Form onSubmit={_handleSubscriptionFormSubmit}>
            <Form.Group controlId="formEmail">
              <Form.Label className={[formStyle.formLabel, formStyle.required]}>Email</Form.Label>
              <Form.Control size="sm" value={subscriptionFormState.email} type="email" name="email" placeholder="Your email adress" onChange={handleSubscriptionFormChange} />
            </Form.Group>

            <Button type="submit" className={[formStyle.btnPrimary, formStyle.submitButtonPink]}>
              Submit
            </Button>
          </Form>

          <Row>
            <Col xs={12} sm={8}>
              {
                showSubscriptionAlert.show && subscriptionFormResult.result === 'error' &&
                <Alert variant="danger" onClose={() => setShowSubscriptionAlert({show: false})} dismissible
                >{showSubscriptionAlert.msg}</Alert>
              }
              {
                showSubscriptionAlert.show && subscriptionFormResult.result === 'success' &&
                <Alert variant="primary" onClose={() => setShowSubscriptionAlert({show: false})} dismissible
                >Thank you for subscribing!</Alert>
              }
              {
                showSubscriptionError.show &&
                <Alert variant="danger" onClose={() => setShowSubscriptionErrorAlert({show: false})} dismissible
                >{showSubscriptionError.onField} field should not be empty</Alert>
              }
            </Col>
          </Row>

        </div>
      </Container>
    </Layout>
  )
};

export const query = graphql`
  query {
    allFile(filter: {relativePath: {regex: "/paid-family-leave\/.*/"}}) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
          relativePath
          name
        }
      }
    }
  }
`;

export default PaidFamilyLeavePage
